import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckInReserva } from '../Modelos/check-in-reserva';
import { Data } from '../Modelos/data';
import { ResultadoCheckIn } from '../Modelos/resultado-check-in';
import { ResultadoConsultaReserva } from '../Modelos/resultado-consulta-reserva';
import { ValidarReserva } from '../Modelos/validar-reserva';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ReservasionService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
    this.pathService = "pms";
  }
  validate(validar:Data<ValidarReserva>) {
    return this.postObservable<ResultadoConsultaReserva[]>("getReservation",validar);
  }
  checkIn(checkin:Data<CheckInReserva>) {
    return this.postObservable<ResultadoCheckIn>("checkin",checkin);
  }
  checkOut(token:Data<ResultadoCheckIn>) {
    return this.postObservable("checkout", token);
  }
}
