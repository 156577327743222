import { dashCaseToCamelCase } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Data } from '../Modelos/data';
import { Despertador } from '../Modelos/despertador';
import { ResultadoCheckIn } from '../Modelos/resultado-check-in';
import { Servicio } from '../Modelos/servicio';
import { ServiciosGeneralesService } from '../Servicios/servicios-generales.service';
declare function clickBoton(params:string):void;
@Component({
  selector: 'app-despertador',
  templateUrl: './despertador.component.html',
  styleUrls: ['./despertador.component.scss']
})
export class DespertadorComponent implements OnInit {
  token:string='';
  despertadores:Despertador[]=[];
  despertador:Despertador=new Despertador();
  constructor(private servicios:ServiciosGeneralesService) { }

  ngOnInit(): void {
    this.token = atob(localStorage.getItem(environment.qc));
    var datos = new Data<ResultadoCheckIn>();
    datos.data = { token:this.token  };
    this.servicios.obtenerServicios(datos).subscribe(t => {
      t.data.filter(t => "RING" == t.projet_id).forEach(t => {
        var e = t.notes.split("|");
        console.info(t), this.despertadores.push({
          del: t.date_debut,
          al: t.date_fin,
          id: this.despertadores.length + 1,
          nombre: e[0],
          hora: e[1]
        }), console.info(this.despertadores)
      })
    })
  }
  new(){
    this.despertador = new Despertador();
  }
  edit(t) {
    this.despertador = t;
  }
  remove(t) {
    let e = [];
    this.despertadores.forEach(n => {
      if(n.id != t)
      {
        e.push(n);
      }
    }), 
    this.despertadores = e;
    localStorage.setItem(environment.Qc, btoa(JSON.stringify(this.despertadores)));
  }
  guardar() {
    if(this.despertador.id==0){
      var datos = new Data<Servicio>();
      datos.data = { 
        token: this.token,
        date_debut:this.despertador.del, 
        date_fin:this.despertador.al, 
        service_id:'RING', 
        detalle:this.despertador.nombre +'|'+this.despertador.hora
      };
  
      this.servicios.agregarServicio(datos).subscribe(t => {
        this.despertador.id = this.despertadores.length + 1;
        this.despertadores.push(this.despertador);
        this.despertador = new Despertador();
        clickBoton('btnCloseDespertador');
        localStorage.setItem(environment.Qc, btoa(JSON.stringify(this.despertadores)));
      });  
    }else{
      this.despertadores[this.despertadores.findIndex(fd=>fd.id == this.despertador.id)]=this.despertador;
      this.despertador = new Despertador(); 
      clickBoton('btnCloseDespertador');
      localStorage.setItem(environment.Qc, btoa(JSON.stringify(this.despertadores)));
    }   
    
  }
}
