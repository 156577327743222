<div class="row justify-content-center">
    <div  class="row mt-3 text-center">
      <div class="col-md-12">
        <h1  class="p-0 m-0">
          <strong >!Hola!</strong>
        </h1>
        <h3 >
          <strong >{{cleinte}}</strong>
        </h3>
      </div>
    </div>
    <div class="col-12 cursorPinter">
      <div class="row mt-3 ">
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center"  (click)="checkin()">
          <img class=" img-fluid rounded p-3 scotiabank-img" src="../../assets/reservas.png"
              alt="Card image cap" >
          <p class="mt-1"> Check-(in/ou)</p>
        </div>
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center "   (click)="despertador()">
          <img class="img-fluid rounded p-3 scotiabank-img" src="../../assets/reloj.png"
              alt="Card image cap">
          <p class="mt-1">Despertador</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center"  (click)="roomservices()">
          <img class=" img-fluid rounded p-3 scotiabank-img" src="../../assets/servicio a cuarto.png"
              alt="Card image cap" >
          <p class="mt-1">Room Service</p>
        </div>
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center "  (click)="hotel()" >
            <img class="img-fluid rounded p-3 scotiabank-img" src="../../assets/hotel.png"
              alt="Card image cap">
          <p class="mt-1">Hotel</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center"  (click)="gastos()">
          <img class=" img-fluid rounded p-3 scotiabank-img" src="../../assets/ic_card_credit.png"
              alt="Card image cap" >
          <p class="mt-1"> MisGastos</p>
        </div>
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center "  (click)="restaurantes()"  >
          <img class="img-fluid rounded p-3 scotiabank-img" src="../../assets/resaurante.png"
              alt="Card image cap">
          <p class="mt-1">Restaurantes y Bares</p>
        </div>
      </div>
    </div>
  </div>