<div class="container scotiabank-general-config" >
    <nav class="navbar  scotiabank-toolbar mx-auto scotiabank-height"   >
        <div class="align-content-start p-1">
            <a class="navbar-brand "  style="color: white" >
                S10 Holiday
            </a>
        </div>
        <div class="p-1" *ngIf="showMwnu">
          <button type="button" (click)="inicio()" class="btn align-content-end"   style="color: white">Menu</button>
        </div>
    </nav>
   <div class="row">
     <div class="col-md-12">
        <router-outlet></router-outlet>
     </div>
   </div>
    <ngb-carousel *ngIf="images" >
        <ng-template ngbSlide>
          <div class="wrapper">
             <img src="../assets/pub_1.jpg" class="" style="width: 100%"  height="180px" alt="Random first slide">
          </div>          
        </ng-template>
        <ng-template ngbSlide>
          <div class="wrapper">
            <img src="../assets/pub_2.jpg"  style="width: 100%" height="180px"   alt="Random second slide">
          </div>         
        </ng-template>
        <ng-template ngbSlide>
            <div class="wrapper">
              <img src="../assets/pub_3.jpg"  style="width: 100%" height="180px"   alt="Random second slide">
            </div>         
          </ng-template>
    </ngb-carousel>
    <div  class="scotiabank-toolbar mx-auto text-center pt-2" 
        style="height: 60px;">
        <p > todos los derechos reservados </p>
    </div>
</div>