<div class="col-md-12">
    <div class="row mt-3 px-3">
      <div class="col-md-12 text-center">
        <h2 class="w-100">
          <strong> Servicio de Entretenimiento </strong>
        </h2>
      </div>
    </div>
    <div class="row mt-3 px-3">
      <div
       
        class="row w-100 rowItem"
        style="max-height: 500px; height: 450px; overflow-y: auto !important"
      >
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <img
               
                src="../../../assets/netflix.png"
                height="100px"
                width="100px"
                alt=""
              />
            </div>
            <div class="col-md-7">
              <p>
                <strong>Netflix</strong>
              </p>
              <p>Peliculas en Streaming</p>
              <p>
                <strong>$200.00</strong> mnx
              </p>
            </div>
            <div class="col-md-2 pt-4">
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="form-check">
                    <input
                    [(ngModel)]="netflix"
                      type="checkbox"
                      id="gridCheck"
                      class="form-check-input ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <img
               
              src="../../../assets/blim.jpg"
                height="100px"
                width="100px"
                alt=""
              />
            </div>
            <div class="col-md-7">
              <p>
                <strong>Blim</strong>
              </p>
              <p>Peliculas en Streaming</p>
              <p>
                <strong>$100.00</strong> mnx
              </p>
            </div>
            <div class="col-md-2 pt-4">
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="form-check">
                    <input
                    [(ngModel)]="blim"
                      type="checkbox"
                      id="gridCheck"
                      class="form-check-input ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              <img
               
              src="../../../assets/disney.jpg"
                height="100px"
                width="100px"
                alt=""
              />
            </div>
            <div class="col-md-7">
              <p>
                <strong>Disney+</strong>
              </p>
              <p>Peliculas en Streaming</p>
              <p>
                <strong>$150.00</strong> mnx
              </p>
            </div>
            <div class="col-md-2 pt-4">
              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="form-check">
                    <input
                    [(ngModel)]="disney"
                      type="checkbox"
                      id="gridCheck"
                      class="form-check-input ng-untouched ng-pristine ng-valid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
            <div class="row">
              <div class="col-md-3">
                <img
                 
                src="../../../assets/hbomax.jpg"
                  height="100px"
                  width="100px"
                  alt=""
                />
              </div>
              <div class="col-md-7">
                <p>
                  <strong>HBOMax</strong>
                </p>
                <p>Peliculas en Streaming</p>
                <p>
                  <strong>$170.00</strong> mnx
                </p>
              </div>
              <div class="col-md-2 pt-4">
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="form-check">
                      <input
                      [(ngModel)]="hbo"
                        type="checkbox"
                        id="gridCheck"
                        class="form-check-input ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="row mb-3 px-3">
      <button
      data-bs-toggle="modal"
      data-bs-target="#exampleModal"
      [disabled]="!netflix && !blim && !disney && !hbo"
      class="offset-md-1 col-md-10 btn btn-lg btn-success"
      (click)="pedido()">
        PEDIR
      </button>
    </div>
  </div>
  
<div
id="exampleModal"
tabindex="-1"
aria-labelledby="exampleModalLabel"
class="modal fade "
aria-modal="true"
role="dialog">
<div  class="modal-dialog modal-dialog-centered modal-sm">
  <div  class="modal-content">
    <div  class="modal-body text-center">
      <h3  class="text-center">
        <span *ngIf="!botonOk"
          
          role="status"
          aria-hidden="true"
          class="spinner-border text-primary"
        ></span
        ><span  class="visually-hidden d-block"
          >{{ mensaje }}</span
        >
      </h3>
    </div>
    <div  class="modal-footer" *ngIf="botonOk">
      <button          
        type="button"
        data-bs-dismiss="modal"
        class="btn btn-info btn-lg col-md-12"
      >
        Ok
      </button>
    </div>
  </div>
</div>
</div>