import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthReserva } from './Auth/auth-reserva';
import { AuthToken } from './Auth/auth-token';
import { CheckinComponent } from './checkin/checkin.component';
import { DespertadorComponent } from './despertador/despertador.component';
import { GastosComponent } from './gastos/gastos.component';
import { HomeComponent } from './home/home.component';
import {MainComponent} from './main/main.component';
import { MapaComponent } from './mapa/mapa.component';
import { RestauranteComponent } from './restaurante/restaurante.component';
import { BebidasComponent } from './RoomService/bebidas/bebidas.component';
import { LavanderiaComponent } from './RoomService/lavanderia/lavanderia.component';
import { MenuComponent } from './RoomService/menu/menu.component';
import { SnacksComponent } from './RoomService/snacks/snacks.component';
import { TvComponent } from './RoomService/tv/tv.component';

const routes: Routes = [

  //{ path: '', redirectTo: 'home', pathMatch: 'full' },
  {path: '', component: MainComponent},
  {path: 'reserva', component: CheckinComponent,canActivate:[AuthReserva]},
  {path: 'menu', component: HomeComponent,canActivate:[AuthToken]},  
  {path: 'despertador', component: DespertadorComponent,canActivate:[AuthToken]},
  {path: 'roomservices', component:MenuComponent ,canActivate:[AuthToken]},
  {path: 'roomservices/lavanderia', component: LavanderiaComponent,canActivate:[AuthToken]},
  {path: 'roomservices/snacks', component: SnacksComponent,canActivate:[AuthToken]},
  {path: 'roomservices/bebidas', component: BebidasComponent,canActivate:[AuthToken]},
  {path: 'roomservices/entretenimiento', component: TvComponent,canActivate:[AuthToken]},
  {path: 'gastos', component: GastosComponent,canActivate:[AuthToken]},
  {path: 'hotel', component: MapaComponent,canActivate:[AuthToken]},
  {path: 'restaurantes-bares', component: RestauranteComponent,canActivate:[AuthToken]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
