import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './main/main.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatToolbarModule} from '@angular/material/toolbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatGridListModule} from '@angular/material/grid-list';
import { DialogNetworkSocialComponent } from './dialog-network-social/dialog-network-social.component';
import {EnrolmentModule} from './enrolment/enrolment.module';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import { HomeComponent } from './home/home.component';
import { CheckinComponent } from './checkin/checkin.component';
import { DespertadorComponent } from './despertador/despertador.component';
import { MapaComponent } from './mapa/mapa.component';
import { RestauranteComponent } from './restaurante/restaurante.component';
import { GastosComponent } from './gastos/gastos.component';
import { MenuComponent } from './RoomService/menu/menu.component';
import { LavanderiaComponent } from './RoomService/lavanderia/lavanderia.component';
import { BebidasComponent } from './RoomService/bebidas/bebidas.component';
import { TvComponent } from './RoomService/tv/tv.component';
import { SnacksComponent } from './RoomService/snacks/snacks.component';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DialogNetworkSocialComponent,
    HomeComponent,
    CheckinComponent,
    DespertadorComponent,
    MapaComponent,
    RestauranteComponent,
    GastosComponent,
    MenuComponent,
    LavanderiaComponent,
    BebidasComponent,
    TvComponent,
    SnacksComponent,
    

  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSliderModule,
    MatToolbarModule,
    NgbModule,
    MatGridListModule,
    EnrolmentModule,
    FormsModule,
    HttpClientModule
  ],
  entryComponents: [
    DialogNetworkSocialComponent
  ],
  providers: [],
  bootstrap: [AppComponent],

})
export class AppModule { }
