import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogEnrolmentComponent } from './dialog-enrolment/dialog-enrolment.component';
import { QuestionSimpleComponent } from './question-simple/question-simple.component';
import { QuestionMultipleComponent } from './question-multiple/question-multiple.component';
import { QuestionTwoComponent } from './question-two/question-two.component';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatRadioModule} from '@angular/material/radio';
import {MatRippleModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import { DialogGeneratePasswordComponent } from './dialog-generate-password/dialog-generate-password.component';
import { DialogInformationComponent } from './dialog-information/dialog-information.component';
import {Autosize} from 'ng-autosize';



@NgModule({
  declarations: [
    DialogEnrolmentComponent,
    QuestionSimpleComponent,
    QuestionMultipleComponent,
    QuestionTwoComponent,
    DialogGeneratePasswordComponent,
    DialogInformationComponent,
    Autosize
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatRadioModule,
    MatRippleModule,
    MatCheckboxModule,
    FormsModule,
    MatListModule,
    ReactiveFormsModule
  ],
})
export class EnrolmentModule { }
