import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '../Modelos/data';
import { ResultadoCheckIn } from '../Modelos/resultado-check-in';
import { ResultadoServicio } from '../Modelos/resultado-servicio';
import { Servicio } from '../Modelos/servicio';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ServiciosGeneralesService extends BaseService {
  constructor(protected http: HttpClient) {
    super(http);
    this.pathService = "pms";
  }

  agregarServicio(validar:Data<Servicio>) {
    return this.postObservable<any>("services",validar);
  }
  obtenerServicios(validar:Data<ResultadoCheckIn>) {
    return this.postObservable<ResultadoServicio[]>("getServices",validar);
  }
}
