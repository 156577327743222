import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Data } from '../Modelos/data';
import { Reserva } from '../Modelos/reserva';
import { ResultadoCheckIn } from '../Modelos/resultado-check-in';
import { Servicio } from '../Modelos/servicio';
import { ServiciosGeneralesService } from '../Servicios/servicios-generales.service';
declare function clickBoton(params:string):void;
@Component({
  selector: 'app-restaurante',
  templateUrl: './restaurante.component.html',
  styleUrls: ['./restaurante.component.scss']
})
export class RestauranteComponent implements OnInit {
  token:string='';
  restaurantes:Reserva[]=[];
  restaurante:Reserva= new Reserva();
  constructor(private servicios:ServiciosGeneralesService) { }

  ngOnInit(): void {
    this.token = atob(localStorage.getItem(environment.qc));
    var datos = new Data<ResultadoCheckIn>();
    datos.data = { token:this.token  }; 
    this.servicios.obtenerServicios(datos).subscribe(t => {
      t.data.filter(t => "REST" == t.projet_id).forEach(t => {
        var e = t.notes.split("|");
        console.info(t), this.restaurantes.push({
          del: t.date_debut,
          al: t.date_fin,
          id: this.restaurantes.length + 1,
          nombre: e[0],
          personas: Number.parseInt(e[2]),
          hora: e[1]
        });
      })
    })
  }
  new(){
    this.restaurante = new Reserva();
  }
  edit(t) {
    this.restaurante = t;
  }
  remove(t) {
    let e = [];
    this.restaurantes.forEach(n => {
      if(n.id != t)
      {
        e.push(n);
      }
    });
    this.restaurantes = e;
    localStorage.setItem(environment.Kc, btoa(JSON.stringify(this.restaurantes)));
  }
  guardar() {
    if(this.restaurante.id==0){
      var datos = new Data<Servicio>();
      datos.data = { 
        token: this.token,
        date_debut:this.restaurante.del, 
        date_fin:this.restaurante.al, 
        service_id:'REST', 
        detalle:this.restaurante.nombre+'|'+this.restaurante.personas +'|'+this.restaurante.hora
      };
  
      this.servicios.agregarServicio(datos).subscribe(t => {
        this.restaurante.id = this.restaurantes.length + 1;
        this.restaurantes.push(this.restaurante);
        this.restaurante = new Reserva();
        clickBoton('btnCloseRestaurante');
        localStorage.setItem(environment.Kc, btoa(JSON.stringify(this.restaurantes)));
      });  
    }else{
      this.restaurantes[this.restaurantes.findIndex(fd=>fd.id == this.restaurante.id)]=this.restaurante;
      this.restaurante = new Reserva(); 
      clickBoton('btnCloseRestaurante');
      localStorage.setItem(environment.Kc, btoa(JSON.stringify(this.restaurantes)));
    }
  }
}
