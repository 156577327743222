import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
export class AuthToken implements CanActivate {
    constructor(private router: Router){}
    canActivate(): boolean  {
       var respuesta:boolean = false;
        try{
            let reservaInfo =JSON.parse(atob(localStorage.getItem(environment.zc)));
            let tok =JSON.parse(atob(localStorage.getItem(environment.qc)));
            respuesta=true;
       }
       catch(e){

       }
       
       if(!respuesta){
        this.router.navigate(['/reserva']);
       }
       return respuesta;
    }


}
