import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showMwnu:boolean=false;
  rutaMenu:string='';
  title = 'ScotiabankWeb';
  images = ['../assets/pub_1.pg',
    '../assets/pub_2.pg',
    '../assets/pub_3.pg'];
  ngOnInit(): void {    
    this.showMwnu = (window.location.pathname != "/menu" && window.location.pathname != "/");
    this.rutaMenu = ((window.location.pathname.indexOf('roomservices/')>-1)?'/roomservices':'/menu');
  }
  inicio(){
    window.location.href = this.rutaMenu;
  }
}
