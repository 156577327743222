import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CheckIn } from '../Modelos/check-in';
import { Data } from '../Modelos/data';
import { EstatusRespuesta } from '../Modelos/estatus-respuesta.enum';
import { ResultadoCheckIn } from '../Modelos/resultado-check-in';
import { ResultadoConsultaReserva } from '../Modelos/resultado-consulta-reserva';
import { ReservasionService } from '../Servicios/reservasion.service';

@Component({
  selector: 'app-checkin',
  templateUrl: './checkin.component.html',
  styleUrls: ['./checkin.component.scss']
})
export class CheckinComponent implements OnInit {
  checkOutState =false;
  token = ''; 
  cliente = ''; 
  del =''; 
  al = ''; 
  noches = 0; 
  dias = 0; 
  nino = 0; 
  adulto = 0; 
  clave = ''; 
  showButton = !1; 
  reserva='';
  mensaje='';
  mostrarBotonOk=false;
  constructor(private reservation:ReservasionService  ) {
    
      this.token = atob(localStorage.getItem(environment.qc));
      let reservaInfo =JSON.parse(atob(localStorage.getItem(environment.zc)));
      this.cliente = reservaInfo.nom;
      this.del = reservaInfo.date_debut;
      this.al= reservaInfo.date_fin;
      this.nino = parseInt(reservaInfo.num_menores);
      this.adulto = parseInt(reservaInfo.num_adultos);
      this.noches = (new Date(this.al).getTime() - new Date(this.del).getTime() ) / 86400000;
      this.dias = this.noches+1;
      this.clave = reservaInfo.codigo_hab;
      this.reserva= reservaInfo.clave_reserva;
      this.checkOutState = (this.token !='');
    
  }

  ngOnInit(): void {
   
  }  
  checkIn():void{
    this.mensaje='Espera por favor, estamos realizando tu CheckIn';
    let datos = new Data<CheckIn>();
    datos.data = {date_debut:this.del, date_fin:this.al, clave_reserva:this.reserva,num_adultos:this.adulto, num_menores:this.nino };
    setTimeout(()=>{
      this.reservation.checkIn(datos).subscribe(t => {
        console.log(t);
        if(t.status == EstatusRespuesta.OK) {
          localStorage.setItem(environment.qc, btoa(t.data.token));
          this.token =t.data.token;
          this.mensaje ='Tu confirmación fue satisfactoria, seras dirigido al menú';
          setTimeout(()=>{
           window.location.href = "/menu";
          },800);
        } else{
          this.mensaje = t.message;
          setTimeout(()=>{
            this.checkOut();
          },800);
        }        
      });    
    },1000);    
  }
  checkOut():void{
    let datos = new Data<ResultadoCheckIn>();
    datos.data = { token:this.token};
    localStorage.removeItem(environment.qc);
    localStorage.removeItem(environment.zc);
    localStorage.removeItem(environment.Wc);
    localStorage.removeItem(environment.Qc);
    localStorage.removeItem(environment.Kc);
    this.mensaje ='Confirmando tu CheckOut, espera por favor.';
   setTimeout(()=>{
    this.reservation.checkOut(datos).subscribe(t => {      
      window.location.href = "/";
    });
   },1000);      
  }
}
