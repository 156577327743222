import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import { MParams } from '../Models/mparams';
import { Observable } from 'rxjs';
import { RespuestaGenerica } from '../Modelos/respuesta-generica';
//import { MResponse } from '../Models/mresponse';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  private endPoint:string='';
    protected h:HttpHeaders=null;
    protected isEndPointRfc:boolean=false;
    protected pathService:string='';
    protected result:any;
    constructor(protected http: HttpClient){
        this.endPoint= environment.endPoint;      
        this.h = new HttpHeaders({   
            'Access-Control-Allow-Origin': 'https://demoschpms.s10plus.com',
            'Access-Control-Allow-Headers':'*',
            'Access-Control-Allow-Methods':'*',
            'Content-Type': 'application/json'
         
        });
    }      
        
    protected postObservable<T>(method:string,post:any):Observable<RespuestaGenerica<T>>{
        return  this.http.post<RespuestaGenerica<T>>(this.endPoint+this.pathService+'/'+method,post,{ headers:this.h});
    } 

}
