import { Component, OnInit } from '@angular/core';
import { Data } from 'src/app/Modelos/data';
import { EstatusRespuesta } from 'src/app/Modelos/estatus-respuesta.enum';
import { Servicio } from 'src/app/Modelos/servicio';
import { ServiciosGeneralesService } from 'src/app/Servicios/servicios-generales.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lavanderia',
  templateUrl: './lavanderia.component.html',
  styleUrls: ['./lavanderia.component.scss']
})
export class LavanderiaComponent implements OnInit {
  toallas:boolean=false;
  recoleccion:boolean=false;
  tintoreria:boolean=false;
  mensaje:string='';
  botonOk:boolean=false;
  constructor(private servicios:ServiciosGeneralesService) { }

  ngOnInit(): void {
  }
  pedido(){
    this.mensaje = 'Espera, estamos procesando tu solicitud.';
    var detallePedido = (this.toallas)?'Toalla para Ba\xf1o|Paquete de 4 Toallas':'';
    detallePedido += (this.recoleccion)?'Recoleccion|Servicio de recoleccion para lavado de ropa':'';
    detallePedido += (this.tintoreria)?'Tintoreria|Servicio de recoleccion para tintoreria':'';
    this.botonOk=false;
    let t = new Date,
    e = t.getFullYear() + "-" + (1 == (t.getMonth() + 1).toString().length ? "0" : "") + (t.getMonth() + 1).toString() + "-" + (1 == t.getDate().toString().length ? "0" : "") + t.getDate().toString();
    var total = (this.toallas)?40:0;
    total += (this.recoleccion)?15:0;
    total += (this.tintoreria)?150:0;
    var datos = new Data<Servicio>();
    datos.data = { 
      token:atob(localStorage.getItem(environment.qc)), 
      date_debut:e, 
      date_fin:e, 
      service_id:'LAV', 
      detalle:detallePedido };
    setTimeout(()=>{
      this.servicios.agregarServicio(datos).subscribe(t=>{
        this.toallas=false;
        this.recoleccion=false;
        this.tintoreria=false;
        this.botonOk=true;
        if(t.status == EstatusRespuesta.OK && t.data != null){
          this.mensaje = 'Gracias por tu pedido';
        }else{
          this.mensaje = t.message;
        }
      });
    },1000);

  }
}
