<div class="modal-header">
  <h4 class="modal-title">Iniciar sesión</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <br>
  <br>


</div>
<div class="modal-body">
  <p class="text-right">{{indexQuestion}}/{{questions.length}}</p>


  <div class="container" >
    <img src="assets/ic_fail.svg" *ngFor="let item of failsQuestion" width="16" height="16">
    <app-question-simple #question_simple [isVisible]="simpleQuestionIsVisible" [question]="currentQuestionSimple"></app-question-simple>
    <app-question-two  #question_one_selection [isVisible]="isVisibleQuestionTwo" [question]="currentQuestionTwo"></app-question-two>
    <app-question-multiple #question_multiple [isVisible]="isVisibleQuestionMultiple" [question]="currentQuestionMultiple"> </app-question-multiple>

    <button mat-raised-button  (click)="nextQuestion()" style="background: #EE3124; color: white">{{this.questions.length === this.indexQuestion?'Validar':'Siguiente'}}</button>

  </div>

</div>




