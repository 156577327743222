<div class="col-md-12">
  <div  class="row mt-3 px-3">
    <div  class="col-md-12 text-center">
      <h2  class="w-100">
        <strong > Mis Reservas </strong
        ><button
          
          id="abirModal"
          data-bs-toggle="modal"
          data-bs-target="#modalReserva"
          class="offset-md-1 col-md-2 btn btn-sm btn-primary"
          (click)="new()"
        >
          <span 
            ><i  class="fas fa-plus"></i> </span
          >
        </button>
      </h2>
    </div>
  </div>
  <div  class="row mt-3 px-3">
    <div      
      class="row w-100 rowItem"
      style="max-height: 500px; height: 450px; overflow-y: auto !important">
      <div  class="col-md-12">
        <div  class="row" *ngFor="let item of restaurantes">
          <div  class="col-md-3">
            <img
              
              height="100px"
              width="100px"
              alt=""
              src="../../assets/{{item.nombre}}.jpg"
            />
          </div>
          <div  class="col-md-7">
            <p ><strong >{{item.nombre}}</strong></p>
            <p >Fecha Reserva {{item.del}} / {{item.al}}</p>
            <p >Hora {{item.hora}}</p>
            <p >
              <strong>
                  <span >
                    <i  class="fas fa-user"></i> 
                </span>
                {{item.personas}} persona(s)
              </strong>
            </p>
          </div>
          <div  class="col-md-2 pt-4">
            <div  class="row mt-2">
              <div  class="col-md-12"
              data-bs-toggle="modal"
              data-bs-target="#modalReserva"
              (click)="edit(item)">              
                <span  class="text-warning">
                    <i  class="fas fa-edit"></i> </span>
              </div>
              <div  class="col-md-12" (click)="remove(item)">
                <span  class="text-danger">
                    <i  class="fas fa-trash"></i></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
<div
  
  id="modalReserva"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  class="modal fade"
  style="display: none"
  aria-hidden="true"
>
  <div
    
    class="modal-dialog modal-dialog-centered modal-sm"
  >
    <div  class="modal-content">
      <div  class="modal-header">
        <h3  id="exampleModalLabel" class="modal-title">
          {{(restaurante.id>0)?'Modificar':'Nueva'}} Reserva
        </h3>
      </div>
      <div  class="modal-body">
        <div  class="row">
          <div  class="col-md-12 mb-3">
            <label
              
              for="exampleFormControlInput1"
              class="form-label"
              >Restaurante</label
            ><input
              [(ngModel)]="restaurante.nombre"
              list="lstResta"
              id="exampleFormControlInput1"
              class="form-control ng-valid ng-dirty ng-touched"
            /><datalist  id="lstResta">
              <option  value="Bar Sanluis"></option>
              <option  value="Cafe Puntacielo"></option>
              <option  value="Punta Polanco"></option>
              <option  value="Discoteq"></option>
            </datalist>
          </div>
          <div  class="col-md-12 mb-3">
            <label
              
              for="exampleFormControlInput1"
              class="form-label"
              >Fecha Del</label
            ><input
            [(ngModel)]="restaurante.del"
              type="date"
              id="exampleFormControlInput1"
              class="form-control ng-valid ng-dirty ng-touched"
            />
          </div>
          <div  class="col-md-12 mb-3">
            <label
              
              for="exampleFormControlInput1"
              class="form-label"
              >Fecha Al</label
            ><input
            [(ngModel)]="restaurante.al"
              type="date"
              id="exampleFormControlInput1"
              class="form-control ng-valid ng-dirty ng-touched"
            />
          </div>
          <div  class="col-md-7 mb-3">
            <label
              
              for="exampleFormControlInput1"
              class="form-label"
              >Hora</label
            ><input
              [(ngModel)]="restaurante.hora"
              type="time"
              id="exampleFormControlInput1"
              class="form-control ng-valid ng-dirty ng-touched"
            />
          </div>
          <div  class="col-md-5 mb-3">
            <label
              
              for="exampleFormControlInput1"
              class="form-label"
              ># Personas</label
            ><input
              [(ngModel)]="restaurante.personas"
              type="number"
              id="exampleFormControlInput1"
              class="form-control ng-valid ng-dirty ng-touched"
            />
          </div>
        </div>
      </div>
      <div  class="modal-footer">
        <button
          
          type="button"
          id="btnCloseRestaurante"
          data-bs-dismiss="modal"
          class="btn btn-secondary"
        >
          Cerrar</button
        ><button  type="button" class="btn btn-primary" (click)="guardar()">
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
