import { Component, OnInit } from '@angular/core';
import { Data } from 'src/app/Modelos/data';
import { EstatusRespuesta } from 'src/app/Modelos/estatus-respuesta.enum';
import { Servicio } from 'src/app/Modelos/servicio';
import { ServiciosGeneralesService } from 'src/app/Servicios/servicios-generales.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-snacks',
  templateUrl: './snacks.component.html',
  styleUrls: ['./snacks.component.scss']
})
export class SnacksComponent implements OnInit {
  coca:number=0;
  sandwich:number=0;
  hotdog:number=0;
  cacahuates:number=0;
  total:number=0;
  mensaje:string='';
  botonOk:boolean=false;
  constructor(private servicios:ServiciosGeneralesService) { }

  ngOnInit(): void {
  }
  pedido(){
    this.mensaje = 'Espera, estamos procesando tu solicitud.';
    var detallePedido = (this.coca>0)?this.coca+' Cocacola 600ml,':'';
    detallePedido += (this.sandwich>0)?this.sandwich+' Sandwitch,':'';
    detallePedido += (this.hotdog>0)?this.hotdog+' HotDog,':'';
    detallePedido += (this.cacahuates>0)?this.cacahuates+' Cacahuates,':'';
    this.botonOk=false;
    let t = new Date,
    e = t.getFullYear() + "-" + (1 == (t.getMonth() + 1).toString().length ? "0" : "") + (t.getMonth() + 1).toString() + "-" + (1 == t.getDate().toString().length ? "0" : "") + t.getDate().toString();
    
    var datos = new Data<Servicio>();
    datos.data = { 
      token:atob(localStorage.getItem(environment.qc)), 
      date_debut:e, 
      date_fin:e, 
      service_id:'RS', 
      detalle:detallePedido };
    setTimeout(()=>{
      this.servicios.agregarServicio(datos).subscribe(t=>{
        this.coca=0;
        this.sandwich=0;
        this.hotdog=0;
        this.cacahuates=0;
        this.botonOk=true;
        if(t.status == EstatusRespuesta.OK && t.data != null){
          this.mensaje = 'Gracias por tu pedido';
        }else{
          this.mensaje = t.message;
        }
      });
    },1000);

  }
}
