import { Component, OnInit } from '@angular/core';
import { Data } from 'src/app/Modelos/data';
import { EstatusRespuesta } from 'src/app/Modelos/estatus-respuesta.enum';
import { Servicio } from 'src/app/Modelos/servicio';
import { ServiciosGeneralesService } from 'src/app/Servicios/servicios-generales.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tv',
  templateUrl: './tv.component.html',
  styleUrls: ['./tv.component.scss']
})
export class TvComponent implements OnInit {
  netflix:boolean=false;
  blim:boolean=false;
  disney:boolean=false;
  hbo:boolean=false;
  mensaje:string='';
  botonOk:boolean=false;
  constructor(private servicios:ServiciosGeneralesService) { }

  ngOnInit(): void {
  }
  pedido(){
    this.mensaje = 'Espera, estamos procesando tu solicitud.';    
    this.botonOk=false;
    let t = new Date,
    e = t.getFullYear() + "-" + (1 == (t.getMonth() + 1).toString().length ? "0" : "") + (t.getMonth() + 1).toString() + "-" + (1 == t.getDate().toString().length ? "0" : "") + t.getDate().toString();
    var detallePedido = (this.netflix)?'Netflix,':'';
    detallePedido += (this.blim)?'Blim,':'';
    detallePedido += (this.disney)?'Disney+,':'';
    detallePedido += (this.hbo)?'HBOMax,':'';
    var total = (this.netflix)?200:0;
    total += (this.blim)?100:0;
    total += (this.disney)?150:0;
    total += (this.hbo)?170:0;
    var datos = new Data<Servicio>();
    datos.data = { 
      token:atob(localStorage.getItem(environment.qc)), 
      date_debut:e, 
      date_fin:e, 
      service_id:'TV', 
      detalle:detallePedido };
    setTimeout(()=>{
      this.servicios.agregarServicio(datos).subscribe(t=>{
        this.netflix=false;
        this.blim=false;
        this.disney=false;
        this.hbo=false;
        this.botonOk=true;
        if(t.status == EstatusRespuesta.OK && t.data != null){
          this.mensaje = 'Gracias por tu pedido';
        }else{
          this.mensaje = t.message;
        }
      });
    },1000);

  }
}
