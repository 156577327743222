
<div class="row justify-content-center">
    <div  class="row mt-3 text-center">
      <div class="col-md-12">
        <h1  class="p-0 m-0">
          <strong >!Servicios al Cuarto!</strong>
        </h1>
        <h3>
          <span >las mejores comodidades a la puerta de tu habitacion</span>
        </h3>
      </div>
    </div>
    <div class="col-12 cursorPinter">
      <div class="row mt-3">
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center" (click)="lavanderia()" >
          <img class=" img-fluid rounded p-3 scotiabank-img" src="../../assets/reservas.png"
              alt="Card image cap" >
          <p class="mt-1"> Lavanderia</p>
        </div>
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center "(click)="snacks()"   >
          <img class="img-fluid rounded p-3 scotiabank-img" src="../../assets/snacks.png"
              alt="Card image cap">
          <p class="mt-1">Snacks</p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6  text-center" (click)="bebidas()" >
          <img class=" img-fluid rounded p-3 scotiabank-img" src="../../assets/drinks.png"
              alt="Card image cap" >
          <p class="mt-1">Bebidas</p>
        </div>
        <div class="col-6 col-lg-6 col-md-6 col-sm-6 col-xl-6 text-center "(click)="entreteniemitno()"   >
          <img class="img-fluid rounded p-3 scotiabank-img" src="../../assets/entreteniemitno.png"
              alt="Card image cap">
          <p class="mt-1">TV</p>
        </div>
      </div>
    </div>
  </div>    