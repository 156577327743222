import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Question} from '../model/Question';

@Component({
  selector: 'app-question-simple',
  templateUrl: './question-simple.component.html',
  styleUrls: ['./question-simple.component.scss']
})
export class QuestionSimpleComponent implements OnInit , OnChanges{
  @Input()
  question: Question = new Question();
  @Input()
  isVisible = false ;

 inputModel: string;
  constructor() {

  }

  public validate(): boolean{
    return this.question.answers.filter(value => value.text.toUpperCase() === this.inputModel.toUpperCase()).length >= 1;

  }
  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.inputModel = '';
  }

}
