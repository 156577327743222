import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-information',
  templateUrl: './dialog-information.component.html',
  styleUrls: ['./dialog-information.component.scss']
})
export class DialogInformationComponent implements OnInit {

  @Input()
  message;
  constructor(public activeModal: NgbActiveModal) {
  }
  ngOnInit(): void {
  }

  OK(): void {
    this.activeModal.close({isSuccess: false});

  }
}
