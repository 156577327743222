
<div class="modal-header">
  <h4 class="modal-title">
    Genera tu contraseña de desbloqueo, la cual no debe de tener dígitos consecutivos más de 2 veces, ni dígitos repetidos más de 2 veces,

  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <br>
  <br>


</div>
<div class="modal-body">

  <div class="container" >
    <mat-form-field class="example-full-width">
      <mat-label>Escribe tu código</mat-label>
      <input matInput placeholder="Código" value="" type="text" minlength="4"  maxlength="4" [formControl]="validate">
    </mat-form-field>
    <mat-error *ngIf="validate.invalid">{{getErrorMessage()}}</mat-error>

  </div>
  <button mat-raised-button  (click)="validateCode()" style="background: #EE3124; color: white" [disabled]="validate.invalid"> Crear</button>

</div>


