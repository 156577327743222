import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DialogNetworkSocialComponent} from '../dialog-network-social/dialog-network-social.component';
import {DialogEnrolmentComponent} from '../enrolment/dialog-enrolment/dialog-enrolment.component';
import {Utils} from '../enrolment/model/Utils';
import {DialogGeneratePasswordComponent} from '../enrolment/dialog-generate-password/dialog-generate-password.component';
import {DialogInformationComponent} from '../enrolment/dialog-information/dialog-information.component';
import { ReservasionService } from '../Servicios/reservasion.service';
import { EstatusRespuesta } from '../Modelos/estatus-respuesta.enum';
import { environment } from 'src/environments/environment';
import { Data } from '../Modelos/data';
import { ValidarReserva } from '../Modelos/validar-reserva';
declare  function ejecutaModal(modal:String):void;
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  mail:String="";
  codigoReserva:String="";
  mensaje:String="";
  mostrarBotonOk:boolean=false;
  constructor(private reservation:ReservasionService ) {
  }
  images = ['https://scotiabankfiles.azureedge.net/scotia-bank-mexico/images/redesign/tiles/fiu-01-35.jpg',
    'https://scotiabankfiles.azureedge.net/scotia-bank-mexico/images/redesign/tiles/tile-instagram.jpg',
    'https://scotiabankfiles.azureedge.net/scotia-bank-mexico/images/redesign/tiles/tile-dll.jpg'];
  ngOnInit(): void {
  }
  validarReservacion(){
    this.mensaje="Validando información, espere por favor...";
    this.mostrarBotonOk=false;
    let datos = new Data<ValidarReserva>();
    datos.data = { email:this.mail,clave_reserva:this.codigoReserva};
    setTimeout(() => {
      this.reservation.validate(datos).subscribe(t => {
        console.log(t);
        if(t.status == EstatusRespuesta.OK && t.data != null) {
          localStorage.setItem(environment.zc, btoa(JSON.stringify(t.data[0])));
          localStorage.setItem(environment.qc, '');
          window.location.href = "/reserva";
        } else{
          this.mensaje = t.message;
          this.mostrarBotonOk=true;
        }
      }, t => {
        console.info(t);
      });
    }, 1000);        
  }
}
