<div class="col-md-12">
  <div class="row mt-3 px-3">
    <div class="col-md-12 text-center">
      <h2 class="w-100">
        <strong> Mis Consumos ($ {{total}} mxn) </strong>
      </h2>
    </div>
  </div>
    <div  class="row mt-3 px-3">
      <div  class="row w-100 rowItem">
        <div  class="col-md-12">
          <div  class="row" *ngFor="let compra of compras">
            <div  class="col-md-3">
              <img      
                src="../../assets/pedido.png"
                height="100px"
                width="100px"
                alt=""
              />
            </div>
            <div  class="col-md-7">
              <p >
                <strong >{{compra.servicio}}</strong>
              </p>
              <p >{{compra.periodo}}</p>
              <p >{{compra.descripcion}}</p>
              <p >
                <strong >${{compra.costo}}</strong> mnx
              </p>
            </div>            
          </div>
        </div>     
      </div>
    </div>    
  </div>
  