<div class="col-md-12">
  <div  class="row p-5 text-center">
    <div  class="col-md-12">
      <h1  class="p-0 m-0">
        <strong >!BEBIDAS!</strong>
      </h1>
      <h4 >seccion en construccion</h4>
    </div>
  </div>
</div>
