<div class="col-md-12">
  <div  class="row mt-3 px-3">
    <div  class="col-md-8">
      <strong > Articulos pedidos ({{coca+sandwich+hotdog+cacahuates}}) </strong>
    </div>
    <div  class="col-md-4">
      <strong > Total $ {{coca*15+sandwich*25+hotdog*45+cacahuates*10}} </strong>
    </div>
  </div>
  <div  class="row mt-3 px-3">
    <div  class="row w-100 rowItem">
      <div  class="col-md-12">
        <div  class="row">
          <div  class="col-md-3">
            <img      
              src="../../../assets/cocacola.jpg"
              height="100px"
              width="100px"
              alt=""
            />
          </div>
          <div  class="col-md-7">
            <p >
              <strong >Cocacola 600ml</strong>
            </p>
            <p >Cocacola de envase no retornable</p>
            <p >
              <strong >$15.00</strong> mnx
            </p>
          </div>
          <div  class="col-md-2 pt-4">
            <div  class="row">
              <div  class="col-md-12">
                <input
                  [(ngModel)]="coca"
                  type="number"
                  value="0"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  class="col-md-12">
        <div  class="row">
          <div  class="col-md-3">
            <img
              src="../../../assets/sandwich.jpg"
              height="100px"
              width="100px"
              alt=""
            />
          </div>
          <div  class="col-md-7">
            <p >
              <strong >Sandwitch</strong>
            </p>
            <p >Sandwitch de 3 jamones con queso</p>
            <p >
              <strong >$25.00</strong> mnx
            </p>
          </div>
          <div  class="col-md-2 pt-4">
            <div  class="row">
              <div  class="col-md-12">
                <input
                [(ngModel)]="sandwich"
                  type="number"
                  value="0"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  class="col-md-12">
        <div  class="row">
          <div  class="col-md-3">
            <img
              src="../../../assets/hotdog.jpg"
              height="100px"
              width="100px"
              alt=""
            />
          </div>
          <div  class="col-md-7">
            <p >
              <strong>HotDog</strong>
            </p>
            <p >Orden de 3 HotDogs</p>
            <p >
              <strong >$45.00</strong> mnx
            </p>
          </div>
          <div  class="col-md-2 pt-4">
            <div  class="row">
              <div  class="col-md-12">
                <input
                [(ngModel)]="hotdog"
                  type="number"
                  value="0"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div  class="col-md-12">
        <div  class="row">
          <div  class="col-md-3">
            <img
              src="../../../assets/cacahuates.jpg"
              height="100px"
              width="100px"
              alt=""
            />
          </div>
          <div  class="col-md-7">
            <p >
              <strong >Cacahuates</strong>
            </p>
            <p >Cacahuates 145g Japoneses</p>
            <p >
              <strong >$10.00</strong> mnx
            </p>
          </div>
          <div  class="col-md-2 pt-4">
            <div  class="row">
              <div  class="col-md-12">
                <input
                [(ngModel)]="cacahuates"
                  type="number"
                  value="0"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div  class="row my-3 px-3">
    <button
    data-bs-toggle="modal"
    data-bs-target="#exampleModal"
    [disabled]="coca==0 && sandwich==0 && hotdog==0 && cacahuates==0"
    class="offset-md-1 col-md-10 btn btn-lg btn-success"
    (click)="pedido()">
    PEDIR
  </button>
</div>
</div>

<div
id="exampleModal"
tabindex="-1"
aria-labelledby="exampleModalLabel"
class="modal fade "
aria-modal="true"
role="dialog">
<div  class="modal-dialog modal-dialog-centered modal-sm">
  <div  class="modal-content">
    <div  class="modal-body text-center">
      <h3  class="text-center">
        <span *ngIf="!botonOk"
          
          role="status"
          aria-hidden="true"
          class="spinner-border text-primary"
        ></span
        ><span  class="visually-hidden d-block"
          >{{ mensaje }}</span
        >
      </h3>
    </div>
    <div  class="modal-footer" *ngIf="botonOk">
      <button          
        type="button"
        data-bs-dismiss="modal"
        class="btn btn-info btn-lg col-md-12"
      >
        Ok
      </button>
    </div>
  </div>
</div>
</div>