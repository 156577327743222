<div class="col-md-12">
  <div class="row px-3">
    <div class="col-md-12">
      <div class="row p-1">
        <div class="col-md-6 offset-md-6">
          <label for="user" class="form-label"
            ><strong> Token </strong></label
          ><input
           [(ngModel)]="token"
            type="text"
            id="user"
            readonly=""
            class="form-control"
          />
        </div>
      </div>
      <div class="row p-1">
        <div class="col-md-12">
          <label for="user" class="form-label"
            ><strong> Reservado por </strong></label
          ><input
            [(ngModel)]="cliente"
            type="text"
            id="user"
            readonly=""
            class="form-control"
          />
        </div>
      </div>
      <div class="row p-1">
        <div class="col-md-6">
          <label for="checkIn" class="form-label"
            ><strong> Llegada (Check-In) </strong></label
          ><input
            [(ngModel)]="del"
            type="text"
            id="checkIn"
            readonly=""
            class="form-control"
          />
        </div>
        <div class="col-md-6">
          <label for="checkOut" class="form-label"
            ><strong> Salida (Check-Out) </strong></label
          ><input
            [(ngModel)]="al"
            type="text"
            id="checkOut"
            readonly=""
            class="form-control"
          />
        </div>
      </div>
      <div class="row p-1">
        <div class="col-md-3">
          <label for="inputAddress2"
            ><strong>Dias</strong></label
          ><input
          [(ngModel)]="dias"
            type="number"
            id="inputAddress2"
            readonly=""
            class="form-control"
          />
        </div>
        <div class="col-md-3">
          <label for="inputAddress"
            ><strong>Noches</strong></label
          ><input
          [(ngModel)]="noches"
            type="number"
            id="inputAddress"
            readonly=""
            class="form-control"
          />
        </div>        
        <div class="col-md-3">
          <label for="inputAddress2"
            ><strong>Niños</strong></label
          ><input
          [(ngModel)]="nino"
            type="number"
            id="inputAddress2"
            readonly=""
            class="form-control"
          />
        </div>
        <div class="col-md-3">
          <label for="inputAddress2"
            ><strong>Adultos</strong></label
          ><input
          [(ngModel)]="adulto"
            type="number"
            id="inputAddress2"
            readonly=""
            class="form-control"
          />
        </div>
      </div>
      <div class="row p-1">
        <div class="col-md-12">
          <label for="inputEmail4"
            ><strong>Acompañantes</strong></label
          >
        </div>
        <div class="col-md-12">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Habitacion</th>
                <th>Nombre</th>
                <th>Edad</th>
                <th>Sexo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{clave}}</td>
                <td>{{cliente}}</td>
                <td>23</td>
                <td>M</td>
              </tr>
              <tr>
                <td>{{clave}}</td>
                <td>Angelica Mendoza Torres</td>
                <td>18</td>
                <td>F</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12 my-2">
              <button
               
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                class="btn btn-lg {{(token == '')?'btn-primary':'btn-secondary'}} col-md-12"
                (click)="checkIn()"
                [disabled]="checkOutState"
              >
                <strong> Check In </strong>
              </button>
            </div>
            <div class="col-md-12">
              <button
               
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
                class="btn {{(token != '')?'btn-danger':'btn-secondary'}} btn-lg col-md-12"
                (click)="checkOut()"
                [disabled]="!checkOutState"
              >
                <strong> Check Out </strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  class="modal fade "
  aria-modal="true"
  role="dialog">
  <div  class="modal-dialog modal-dialog-centered modal-sm">
    <div  class="modal-content">
      <div  class="modal-body text-center">
        <h3  class="text-center">
          <span *ngIf="!mostrarBotonOk"
            
            role="status"
            aria-hidden="true"
            class="spinner-border text-primary"
          ></span
          ><span  class="visually-hidden d-block"
            >{{ mensaje }}</span
          >
        </h3>
      </div>
      <!-- <div  class="modal-footer" *ngIf="mostrarBotonOk">
        <button          
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-info btn-lg col-md-12"
        >
          Ok
        </button>
      </div> -->
      <!---->
    </div>
  </div>
</div>