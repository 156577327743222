<div *ngIf="isVisible">
  <mat-label>{{question.text}}</mat-label>
  <br>
  <section  >

    <mat-list-item *ngFor="let item of question.answers">
      <mat-checkbox
        (change)="listChange(item,$event.checked)"
      >{{ item.text }}</mat-checkbox>
    </mat-list-item>

  </section>

</div>
