import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Question} from '../model/Question';

@Component({
  selector: 'app-question-two',
  templateUrl: './question-two.component.html',
  styleUrls: ['./question-two.component.scss']
})
export class QuestionTwoComponent implements OnInit, OnChanges {

  constructor() { }
  @Input()
  question: Question = new Question();
  @Input()
  isVisible = false ;

  checkModel;
  ngOnInit(): void {
  }

  public validate(): boolean{
    return this.question.answers.find(value => value.text === this.checkModel).isCorrect;

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkModel = null;
  }
}
