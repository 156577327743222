import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  lavanderia(){
    window.location.href = '/roomservices/lavanderia';
  }
  snacks(){
    window.location.href = '/roomservices/snacks';
  }
  bebidas(){
    window.location.href = '/roomservices/bebidas';
  }
  entreteniemitno(){
    window.location.href = '/roomservices/entretenimiento';
  }
}
