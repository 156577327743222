
<form  style="width: 100%;" *ngIf="isVisible">
  <mat-label  >{{question.text}}</mat-label>
  <mat-form-field style="width: 100%;">
    <input matInput placeholder="Ingresa tu resuesta" [(ngModel)]="inputModel" name="first"   >
  </mat-form-field>



</form>
