<div class="col-md-12">
  <div class="row">
    <div class="col-md-12 text-center mt-3">
      <img src="../../assets/logo.png" height="90px" alt="" />
    </div>
    <div class="row pt-5 pb-1 mt-1">
      <div class="col-md-12 text-center">
        <h1>
          <strong>BIENVENIDO</strong>
        </h1>
        <p>escribe tu correo u clave de reserva.</p>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="offset-md-2 col-md-8">
            <div class="row">
              <div class="form-group col-md-12">
                <label for="txtMail">
                  <strong>Correo</strong>
                </label>
                <input
                  id="txtMail"
                  type="text"
                  class="form-control ng-untouched ng-pristine ng-valid"
                  [(ngModel)]="mail"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="txtClaveReservs">
                  <strong>Codigo de Reserva</strong>
                </label>
                <input
                  id="txtClaveReservs"
                  type="text"
                  [(ngModel)]="codigoReserva"
                  class="form-control ng-untouched ng-pristine ng-valid"
                />
              </div>
              <div class="form-group col-md-12">
                <button
                  type="button"
                  data-bs-toggle="modal" 
                  data-bs-target="#exampleModal"
                  class="btn btn-primary btn-lg col-md-12"
                  (click)="validarReservacion()">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  class="modal fade "
  aria-modal="true"
  role="dialog">
  <div  class="modal-dialog modal-dialog-centered modal-sm">
    <div  class="modal-content">
      <div  class="modal-body text-center">
        <h3  class="text-center">
          <span *ngIf="!mostrarBotonOk"
            
            role="status"
            aria-hidden="true"
            class="spinner-border text-primary"
          ></span
          ><span  class="visually-hidden d-block"
            >{{ mensaje }}</span
          >
        </h3>
      </div>
      <div  class="modal-footer" *ngIf="mostrarBotonOk">
        <button          
          type="button"
          data-bs-dismiss="modal"
          class="btn btn-info btn-lg col-md-12"
        >
          Ok
        </button>
      </div>
      <!---->
    </div>
  </div>
</div>