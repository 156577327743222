import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Data } from '../Modelos/data';
import { ResultadoCheckIn } from '../Modelos/resultado-check-in';
import { ResultadoServicio } from '../Modelos/resultado-servicio';
import { ServiciosGeneralesService } from '../Servicios/servicios-generales.service';

@Component({
  selector: 'app-gastos',
  templateUrl: './gastos.component.html',
  styleUrls: ['./gastos.component.scss']
})
export class GastosComponent implements OnInit {
  compras:any[]=[];
  total=0;
  constructor(private servicios:ServiciosGeneralesService) { }

  ngOnInit(): void {
    var datos = new Data<ResultadoCheckIn>();
    datos.data = { token:atob(localStorage.getItem(environment.qc))}; 
    
    
    this.servicios.obtenerServicios(datos).subscribe(t => {
      t.data.forEach(f => {
        this.total +=f.costo;
        let e = "";
        switch(f.projet_id) {
          case "RING":
            e = "Alarma despertador";
            break;
          case "HK":
            e = "House Keeping";
            break;
          case "LAV":
            e = "Lavander\xeda";
            break;
          case "RSV":
            e = "Reservaciones de habitaciones";
            break;
          case "REST":
            e = "Restaurant";
            break;
          case "RS":
            e = "Room Service";
            break;
          case "TV":
            e = "VoD/PPV";
            break;
          default:
            e = "otro"
        }
        this.compras.push({
          costo: f.costo,
          servicio: e,
          periodo: f.date_debut == f.date_fin ? f.date_fin : f.date_debut + "/" + f.date_fin,
          descripcion: f.notes
        })
      })
    });
  }

}
